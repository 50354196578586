.patient-disclosure-container {
  display: flex;
  justify-content: center;
  margin-top: 32px;
  .patient-disclosure {
    width: 70%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border: 2px solid $lightgrey-base;
    border-radius: 10px;
    .main-form-area {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: calc(100% - 20px);
      padding: 12px 24px;
      .logo-and-title {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        margin-top: 24px;
        .dropzone,
        .empty {
          width: 300px;
          height: 150px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          text-align: center;
          margin: 0 24px;
        }
        .dropzone {
          width: 100%;
          border: 2px dashed $grey-base;
          border-radius: 10px;
          padding: 28px;
          cursor: pointer;
          &.accepted {
            border: 2px dashed $blue-base;
            background-color: rgba($blue-base, 0.08);
          }
          &.rejected {
            border: 2px dashed $red-base;
            background-color: rgba($red-base, 0.08);
          }
          label {
            text-align: center;
            font-family: 'LatoRegular';
            font-size: 18px;
            color: $blue-base;
            cursor: pointer;
            text-decoration: underline;
          }
        }
        .logo-upload {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          .uploaded-file-container {
            width: 100%;
            border: 2px solid $lightgrey-base;
            border-radius: 10px;
            margin: 8px 0 16px 0;
            display: flex;
            flex-direction: column;
            align-items: space-between;
            overflow: hidden;

            .file-name-and-close {
              width: 100%;
              padding: 16px;
              display: flex;
              flex-direction: row;
              justify-content: space-between;
              button {
                border: none;
                background-color: transparent;
                cursor: pointer;
              }
              .close-button {
                width: 24px;
              }
            }
            .loading-bar {
              width: 100%;
              height: 4px;
              background-color: transparent;
              animation-name: progress-bar;
              animation-duration: 1s;
            }
            @keyframes progress-bar {
              0% {
                width: 0%;
                background-color: $blue-base;
              }
              90% {
                width: 100%;
                background-color: $blue-base;
              }
              100% {
                background-color: transparent;
              }
            }
          }
          h3 {
            font-size: 18px;
            font-family: 'LatoRegular';
            font-weight: 400;
            margin-bottom: 12px;
          }
        }
        h2 {
          font-size: 32px;
          font-family: "'LatoBold'";
          font-weight: 700;
          margin: 24px 0;
          text-align: center;
        }
      }
      .patient-disclosure-form {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: cente∂r;
        text-align: left;
        width: 100%;
        margin-top: 32px;
        input[type='tel'],
        input[type='text'],
        input[type='number'] {
          width: 150px;
          border: none;
          border-bottom: 1px solid $black-base;
          padding: 2px;
          margin: 0;
          font-size: 16px;
          font-family: 'LatoRegular';
          margin-bottom: 6px;
        }
        input::-webkit-outer-spin-button,
        input::-webkit-inner-spin-button {
          -webkit-appearance: none;
          margin: 0;
        }
        input[type='number'] {
          -moz-appearance: textfield;
        }
        p {
          font-size: 16px;
          font-family: "'LatoBold'";
          font-weight: 400;
          margin: 10px 0;
        }
        .field-paragraph {
          width: 90%;
          display: inline-block;
          margin: 24px 0;
        }
        label {
          font-size: 16px;
          font-family: 'LatoRegular';
          font-weight: 400;
        }
        .optional-fields-outer {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          width: 80%;
          .optional-fields-inner {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            width: 80%;
          }
          .field-row {
            display: flex;
            flex-direction: row;
            align-items: center;
            text-wrap: nowrap;
            width: 100%;
            height: fit-content;
            padding: 0;
            margin: 10px 0;
            &.indented {
              padding-left: 10%;
            }
          }
        }
        button {
          margin-top: 24px;
        }
      }
      a {
        margin-top: 12px;
        color: $blue-base;
        font-size: 16px;
        font-family: 'LatoRegular';
      }
    }
    .color-sidebar {
      width: 20px;
      height: 100%;
      .red,
      .orange,
      .purple,
      .blue,
      .green {
        width: 100%;
        height: 20%;
      }
      .red {
        background-color: $marketing-red;
        border-radius: 8px 0 0 0;
      }
      .orange {
        background-color: $marketing-orange;
      }
      .purple {
        background-color: $marketing-purple;
      }
      .blue {
        background-color: $marketing-blue;
      }
      .green {
        background-color: $marketing-green;
        border-radius: 0 0 0 8px;
      }
    }
  }
}
