//Any changes to this file should also be made in inline-colors.ts

//BLACK
$black-base: #00000e;
$black-light: #707077;
//WHITE
$white-base: #ffffff;
//GREY
$grey-base: #c6c5c5;
$grey-light: #eae9e9;
//LIGHT GREY
$lightgrey-base: #e5e5e4;
$lightgrey-light: #f5f5f5;
$lightgrey-lighter: #fcfcfc;
//DARK GREY
$darkgrey-base: #6d6d6d;
$darkgrey-light: #adadad;
$darkgrey-lighter: #c8c8c8;

//RED
$red-base: #bf2536;
$red-light: #fdf6f7;
$red-background: #bf25360f;
//BLUE
$lightblue-base: #f2fbff;
$blue-base: #1d6c8b;
//GREEN
$green-base: #43a03c;
$green-light: #ecf6ec;
//PURPLE
$purple-base: #792e9e;
$purple-background: #792e9e0f;
// ORANGE
$orange-base: #f17c31;

$color-primary: #d03127; // Primary
$color-secondary: #981c23; // Secondary
$color-red: $color-primary;
$color-orange: $orange-base;
$color-purple: $purple-base;
$color-blue: #32459b;
$color-green: #9aaf43;
//MARKETING COLORS
$marketing-red: #c22638;
$marketing-orange: #f37b21;
$marketing-purple: #7928a1;
$marketing-blue: #30429e;
$marketing-green: #9ab03a;
